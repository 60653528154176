<template>
  <club-layout :title="clubData.status === 200 ? clubData.data.Name + ' / ' + $t('breadcrumb.club.sync') : $t('club.notFound.title')" :is-loading="false">
    <template #content v-if="clubData.status === 200">

      <div class="row">
        <div class="col">
          <h5 class="mb-3">{{ $t('club.sync.provider') }}</h5>

          <div class="row mb-3">
            <div class="col">
              <p class="text-muted">{{ $t('club.sync.information') }}</p>
            </div>
          </div>


          <div class="form-floating has-validation mb-3">
            <select id="provider" class="form-select" :class="{'is-invalid': isEmpty(contactClubInterface.Club_Interface_Provider)}" v-model="contactClubInterface.Club_Interface_Provider" @change="analyzeContactClubInterface">
              <option value="">{{ $t('club.sync.noProvider') }}</option>
              <option :value="provider.Code" v-for="provider in clubInterfaceProviders" :key="provider">{{ provider.Name }}</option>
            </select>
            <label for="provider" class="form-label">{{ $t('club.sync.provider') }}</label>
            <div class="invalid-feedback" v-show="isEmpty(contactClubInterface.Club_Interface_Provider)">{{ $t('club.sync.invalidProvider') }}</div>
          </div>

          <div class="form-floating mb-3">
            <input type="text" id="token" class="form-control" :value="contactClubInterface.WebService_ID" :placeholder="$t('club.sync.token')" readonly>
            <label for="token" class="form-label">{{ $t('club.sync.token') }}</label>
          </div>

          <div class="d-flex align-items-center mb-3">
            <ToggleSwitch input-id="dataProtection" v-model="contactClubInterface.Accept_Privacy_Policy" :disabled="privacyPolicyAccepted" :dt="tsDt"/>
            <Translation keypath="club.sync.dataProtection.text" tag="label" for="dataProtection" class="form-check-label ms-2">
              <a :href="contactClubInterface.Link_Privacy_Policy" target="_blank">{{ $t('user.onboarding.privacyLinkText') }}</a>
            </Translation>
          </div>

          <Button
            unstyled class="btn btn-outline-primary"
            role="button"
            :label="$t('club.save.data')"
            @click="submitSyncData"
            :disabled="!allDataValid || privacyPolicyAccepted"/>

        </div>
      </div>

      <div id="toastWrapper" class="position-fixed bottom-0 end-0 p-3" style="z-index: 1000;"></div>
      <BootstrapToast/>
    </template>

    <template #content v-else>
      <NoClubFoundAlert/>
    </template>
  </club-layout>
</template>

<script setup>
import ClubLayout from "@/components/club/ClubLayout.vue";
import ToggleSwitch from "primevue/toggleswitch";
import Button from "primevue/button";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import {computed, onBeforeMount, ref} from "vue";
import {useClubStore} from "@/store/modules/club/club";
import {Translation, useI18n} from "vue-i18n";
import {useContactStore} from "@/store/modules/contact/contact";
import {useRouter} from "vue-router";
import {useMessagesStore} from "@/store/modules/messages/messages";
import Message from "@/helpers/message";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";

const router = useRouter();
const clubStore = useClubStore();
const contactStore = useContactStore();
const messageStore = useMessagesStore();
const {t} = useI18n();

const clubData = computed(() => clubStore.getClubData);
const clubInterfaceProviders = computed(() => contactStore.getClubInterfaceProviders);
const currentContactClubInterface = computed(() => contactStore.getClubInterface);

let contactClubInterface = ref({
  Contact_No: currentContactClubInterface.value.Contact_No,
  Accept_Privacy_Policy: currentContactClubInterface.value.Accept_Privacy_Policy,
  Link_Privacy_Policy: currentContactClubInterface.value.Link_Privacy_Policy,
  Club_Interface_Provider: currentContactClubInterface.value.Club_Interface_Provider,
  WebService_ID: currentContactClubInterface.value.WebService_ID,
});

let showFailure = ref(false);
let showSuccess = ref(false);
let isSubmitted = ref(false);

const allDataValid = computed(() => {
  // return allTrue(dataValid.value);
  return !isEmpty(contactClubInterface.value.Club_Interface_Provider) && contactClubInterface.value.Accept_Privacy_Policy;
});

const privacyPolicyAccepted = computed(() => {
  return currentContactClubInterface?.value && (currentContactClubInterface.value.Accept_Privacy_Policy);
});

const analyzeContactClubInterface = () => {
  // checkProperties(contactClubInterface.value);
  isSubmitted.value = false;
};

const isEmpty = (str) => {
  return !str?.length;
};

const submitSyncData = () => {
  if (allDataValid.value) {
    isSubmitted.value = true;

    saveContactClubInterface().then(async response => {
      isSubmitted.value = true;
      if (response?.status >= 200 && response?.status < 300) {
        showSuccess.value = true;
        messageStore.addInfo(new Message('info', true, false, t('club.save.data'), '', t('club.save.success'), false, 'COMPONENT'));
        await reloadContactClubInterfaceData(contactClubInterface.value.Contact_No);
      } else {
        showFailure.value = true;
        let errorMessage = '';
        if (response.data) {
          errorMessage = response.data;
        } else if (response.response.data) {
          errorMessage = response.response.data;
        }
        messageStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), '', t(errorMessage), false, 'COMPONENT'));
      }
    }).catch(error => {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), '', error, false, 'COMPONENT'));
    }).finally(() => {
      if (showSuccess.value) {
        router.push({
          name: 'club-sync',
          query: {clubId: clubData.value.data.No}
        });
      }

      isSubmitted.value = false;
      window.scrollTo(0, 0);
    });

  }
};

const reloadContactClubInterfaceData = async (clubNumber) => {
  return contactStore.loadClubInterface(clubNumber);
};

const saveContactClubInterface = async () => {
  return contactStore.saveClubInterface(contactClubInterface.value).then(response => {
    return response;
  });
};

onBeforeMount(async () => {
  await contactStore.loadClubInterfaceProviders();
});
</script>

