import ApiClient from "@/api/ApiClient";

import {defineStore} from "pinia";

const CLUB_MEMBER_BASE_PATH = "/api/clubmember";
const ASSOCIATION_MEMBER_BASE_PATH = "/api/associationmember";


export const useClubMemberStore = defineStore("clubMember", {
  state: () => {
    return {
      useCache: true,
      clubAddressRelations: [],
      clubInstituteAddresses: [],
      addressActions: [],
      honors: []
    };
  },

  actions: {
    async loadClubAddressRelations(clubNo, contactNo) {
      const response = await ApiClient.getRequest(CLUB_MEMBER_BASE_PATH + '/address-collections?clubNo=' + clubNo + '&contactNo=' + contactNo);
      if (response?.status < 500 && response?.data) {
        this.clubAddressRelations = response.data;
      } else {
        this.clubAddressRelations = [];
      }
    },

    async loadAssociationAddressRelations(clubNo, contactNo) {
      const response = await ApiClient.getRequest(ASSOCIATION_MEMBER_BASE_PATH + '/address-collections?associationNo=' + clubNo + '&contactNo=' + contactNo);
      if (response?.status < 500 && response?.data) {
        this.clubAddressRelations = response.data;
      } else {
        this.clubAddressRelations = [];
      }
    },

    async loadClubInstituteAddresses(clubNo, contactNo) {
      const response = await ApiClient.getRequest(CLUB_MEMBER_BASE_PATH + '/institute-addresses?clubNo=' + clubNo + '&contactNo=' + contactNo);
      if (response?.status < 500 && response?.data) {
        this.clubInstituteAddresses = response.data;
      } else {
        this.clubInstituteAddresses = [];
      }
    },

    async loadAssociationInstituteAddresses(clubNo, contactNo) {
      const response = await ApiClient.getRequest(ASSOCIATION_MEMBER_BASE_PATH + '/institute-addresses?associationNo=' + clubNo + '&contactNo=' + contactNo);
      if (response?.status < 500 && response?.data) {
        this.clubInstituteAddresses = response.data;
      } else {
        this.clubInstituteAddresses = [];
      }
    },

    async loadAddressActions(clubNo, contactNo) {
      const response = await ApiClient.getRequest(CLUB_MEMBER_BASE_PATH + '/address-actions?clubNo=' + clubNo + '&contactNo=' + contactNo)
      if (response?.status < 500 && response?.data) {
        this.addressActions = response.data;
      } else {
        this.addressActions = [];
      }
    },

    async loadAssociationAddressActions(clubNo, contactNo) {
      const response = await ApiClient.getRequest(ASSOCIATION_MEMBER_BASE_PATH + '/address-actions?associationNo=' + clubNo + '&contactNo=' + contactNo)
      if (response?.status < 500 && response?.data) {
        this.addressActions = response.data;
      } else {
        this.addressActions = [];
      }
    },

    // Inklusive der Ehrungen, die durch untergeordnete Vereine oder Verbände vergeben wurden.
    async loadHonorsAssociation(associationNo, contactNo) {
      const response = await ApiClient.getRequest(ASSOCIATION_MEMBER_BASE_PATH + '/honors?associationNo=' + associationNo + '&contactNo=' + contactNo)
      if (response?.status < 500 && response?.data) {
        this.honors = response.data;
      } else {
        this.honors = [];
      }
    },

    async loadHonors(clubNo, contactNo) {
      const response = await ApiClient.getRequest(CLUB_MEMBER_BASE_PATH + '/honors?clubNo=' + clubNo + '&contactNo=' + contactNo)
      if (response?.status < 500 && response?.data) {
        this.honors = response.data;
      } else {
        this.honors = [];
      }
    },

    async saveClubMember(payload) {
      const response = await ApiClient.postRequestWithAuthorization(CLUB_MEMBER_BASE_PATH + "/update/club-member?id=" + payload.releaseMemberId + "&clubId=" + payload.clubId, payload.baseData.value);
      if (response) {
        return response;
      }
      return null;
    },

    async saveClubSponsor(payload) {
      const response = await ApiClient.postRequestWithAuthorization(CLUB_MEMBER_BASE_PATH + "/update/club-sponsor?id=" + payload.releaseMemberId + "&clubId=" + payload.clubId, payload.baseData.value);
      if (response) {
        return response;
      }
      return null;
    },
    async clearClubData() {
      this.clubAddressRelations = [];
      this.clubInstituteAddresses = [];
      this.addressActions = [];
    },
  },

  getters: {
    getClubAddressRelations: (state) => {
      if (state.clubAddressRelations) {
        return state.clubAddressRelations;
      }
      return null;
    },
    getClubInstituteAddresses: (state) => {
      if (state.clubInstituteAddresses) {
        return state.clubInstituteAddresses;
      }
      return null;
    },
    getAddressActions: (state) => {
      if (state.addressActions) {
        return state.addressActions;
      }
      return null;
    },
    getHonors: (state) => {
      if(state.honors) {
        return state.honors;
      }
      return null;
    }
  },
  persist: {
    storage: sessionStorage,
  },
});
