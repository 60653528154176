import EditProfileView from "@/views/association/EditProfileView.vue";
import AssociationPaymentView from "@/views/association/AssociationPaymentView.vue";
import ShowMembersView from "@/views/association/ShowMembersView.vue";
import ShowSyncView from "@/views/association/ShowSyncView.vue";
import EditMemberView from "@/views/association/EditMemberView.vue";


const ASSOC_BASE_PATH = '/association';
const ASSOC_BASE_NAME = 'association-';

export const ASSOC_EDIT_PROFILE = {path: ASSOC_BASE_PATH + '/editProfile', name: ASSOC_BASE_NAME + 'profile', component: EditProfileView};
export const ASSOC_SHOW_PAYMENTS = {path: ASSOC_BASE_PATH + '/payment', name: ASSOC_BASE_NAME + 'payment', component: AssociationPaymentView};
export const ASSOC_SHOW_GROUPS = {path: ASSOC_BASE_PATH + '/groups', name: ASSOC_BASE_NAME + 'groups', component: ''};
export const ASSOC_SHOW_SYNC = {path: ASSOC_BASE_PATH + '/sync', name: ASSOC_BASE_NAME + 'sync', component: ShowSyncView};
export const ASSOC_SHOW_CLUBS = {path: ASSOC_BASE_PATH + '/clubs', name: ASSOC_BASE_NAME + 'clubs', component: ''};
export const ASSOC_SHOW_ETAT = {path: ASSOC_BASE_PATH + '/etat', name: ASSOC_BASE_NAME + 'etat', component: ''};
export const ASSOC_EDIT_MEMBER = {path: ASSOC_BASE_PATH + '/edit-member', name: ASSOC_BASE_NAME + 'edit-member', component: EditMemberView};
export const ASSOC_SHOW_MEMBERS = {path: ASSOC_BASE_PATH + '/members', name: ASSOC_BASE_NAME + 'members', component: ShowMembersView};
export const ASSOC_SHOW_FUNCTIONS_AND_ACTIVITIES = {path: ASSOC_BASE_PATH + '/functions-activities', name: ASSOC_BASE_NAME + 'functions-activities', component: ''};
export const ASSOC_SHOW_HONORS = {path: ASSOC_BASE_PATH + '/honors', name: ASSOC_BASE_NAME + 'honors', component: ''};
export const ASSOC_SHOW_MEMBER_NUMBERS = {path: ASSOC_BASE_PATH + '/member-numbers', name: ASSOC_BASE_NAME + 'member-numbers', component: ''};
export const ASSOC_SHOW_LSPA = {path: ASSOC_BASE_PATH + '/lspa', name: ASSOC_BASE_NAME + 'lspa', component: ''};


export default {
  ASSOC_BASE_PATH,
  ASSOC_EDIT_PROFILE,
  ASSOC_SHOW_PAYMENTS,
  ASSOC_SHOW_GROUPS,
  ASSOC_SHOW_SYNC,
  ASSOC_SHOW_CLUBS,
  ASSOC_SHOW_ETAT,
  ASSOC_SHOW_MEMBERS,
  ASSOC_EDIT_MEMBER,
  ASSOC_SHOW_MEMBER_NUMBERS,
  ASSOC_SHOW_LSPA,
  ASSOC_SHOW_HONORS,
  ASSOC_SHOW_FUNCTIONS_AND_ACTIVITIES
};
