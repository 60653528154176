<template>
  <association-layout title="Sync" :is-loading="false">
    <template #content v-if="clubData.status === 200">
      <div class="row">
        <div class="col">
          <h5 class="mb-3">{{ $t('club.sync.provider') }}</h5>

          <div class="row mb-3">
            <div class="col">
              <p class="text-muted">{{ $t('association.sync.information') }}</p>
            </div>
          </div>

          <div class="form-floating has-validation mb-3">
            <select id="provider" class="form-select" :class="{'is-invalid': isEmpty(contactClubInterface.Club_Interface_Provider)}" v-model="contactClubInterface.Club_Interface_Provider">
              <option :value="null">{{ $t('club.sync.noProvider') }}</option>
              <option :value="provider.Code" v-for="provider in clubInterfaceProviders" :key="provider">{{ provider.Name }}</option>
            </select>
            <label for="provider" class="form-label">{{ $t('club.sync.provider') }}</label>
            <div class="invalid-feedback" v-show="isEmpty(contactClubInterface.Club_Interface_Provider)">{{ $t('club.sync.invalidProvider') }}</div>
          </div>

          <div class="form-floating mb-3">
            <input type="text" id="token" class="form-control" :value="contactClubInterface.WebService_ID" :placeholder="$t('club.sync.token')" readonly>
            <label for="token" class="form-label">{{ $t('club.sync.token') }}</label>
          </div>

          <div class="d-flex align-items-center mb-3">
            <ToggleSwitch input-id="dataProtection" v-model="contactClubInterface.Accept_Privacy_Policy" :disabled="privacyPolicyAccepted" :dt="tsDt"/>
            <Translation keypath="club.sync.dataProtection.text" tag="label" for="dataProtection" class="form-check-label ms-2">
              <a :href="contactClubInterface.Link_Privacy_Policy" target="_blank">{{ $t('user.onboarding.privacyLinkText') }}</a>
            </Translation>
          </div>

          <Button
            unstyled class="btn btn-outline-primary"
            role="button"
            :label="$t('club.save.data')"
            @click="submitSyncData"
            :disabled="!allDataValid || privacyPolicyAccepted"/>
        </div>
      </div>
    </template>
    <template #content v-else>
      <NoClubFoundAlert/>
    </template>
  </association-layout>

  <BootstrapToast/>
</template>

<script setup>
import AssociationLayout from "@/components/association/AssociationLayout.vue";
import BootstrapToast from "@/components/ui/BootstrapToast.vue";
import NoClubFoundAlert from "@/components/ui/alert/NoClubFoundAlert.vue";
import ToggleSwitch from "primevue/toggleswitch";
import Button from "primevue/button";
import {useRouter} from "vue-router";
import {useClubStore} from "@/store/modules/club/club";
import {useMessagesStore} from "@/store/modules/messages/messages";
import {useContactStore} from "@/store/modules/contact/contact";
import {Translation, useI18n} from "vue-i18n";
import {computed, onBeforeMount, ref} from "vue";
import Message from "@/helpers/message";
import urls_association from "@/router/urls_association";

const router = useRouter();
const clubStore = useClubStore();
const messageStore = useMessagesStore();
const contactStore = useContactStore();
const {t} = useI18n();

const clubData = computed(() => clubStore.getClubData);
const clubInterfaceProviders = computed(() => contactStore.getClubInterfaceProviders);
const currentContactClubInterface = computed(() => contactStore.getClubInterface);

const contactClubInterface = ref({
  Contact_No: currentContactClubInterface.value.Contact_No,
  Accept_Privacy_Policy: currentContactClubInterface.value.Accept_Privacy_Policy,
  Link_Privacy_Policy: currentContactClubInterface.value.Link_Privacy_Policy,
  Club_Interface_Provider: currentContactClubInterface.value.Club_Interface_Provider,
  WebService_ID: currentContactClubInterface.value.WebService_ID,
});

const showFailure = ref(false);
const showSuccess = ref(false);
const isSubmitted = ref(false);

const tsDt = ref();

const allDataValid = computed(() => {
  return !isEmpty(contactClubInterface.value.Club_Interface_Provider) && contactClubInterface.value.Accept_Privacy_Policy;
});

const privacyPolicyAccepted = computed(() => {
  return currentContactClubInterface?.value?.Accept_Privacy_Policy;
});

const isEmpty = (value) => {
  return !value?.length;
}

const submitSyncData = () => {
  if (allDataValid.value) {
    isSubmitted.value = true;
    saveContactClubInterface().then(async response => {
      if (response?.status >= 200 && response?.status < 300) {
        showSuccess.value = true;
        messageStore.addInfo(new Message('info', true, false, t('club.save.data'), '', t('club.save.success'), false, 'COMPONENT'));
        await reloadContactClubInterfaceData(contactClubInterface.value.Contact_No);
      } else {
        showFailure.value = true;
        let errorMessage = '';
        if (response.data) {
          errorMessage = response.data;
        } else if (response.response.data) {
          errorMessage = response.response.data;
        }
        messageStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), '', t(errorMessage), false, 'COMPONENT'));
      }
    }).catch(error => {
      showFailure.value = true;
      messageStore.addError(new Message('error', true, false, t('errorMessages.generic_error'), '', error, false, 'COMPONENT'));
    }).finally(() => {
      if (showSuccess.value) {
        router.push({
          name: urls_association.ASSOC_SHOW_SYNC.name,
          query: {associationId: clubData.value.data.No}
        });
      }

      isSubmitted.value = false;
      window.scrollTo(0, 0);
    });
  }
};

const saveContactClubInterface = async () => {
  return contactStore.saveClubInterface(contactClubInterface.value).then(response => {
    return response;
  });
};

const reloadContactClubInterfaceData = async (clubNumber) => {
  return contactStore.loadClubInterface(clubNumber);
};

onBeforeMount(async () => {
  await contactStore.loadClubInterfaceProviders();
});
</script>
